import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({
  name: "clpCurrency",
})
export class ChileanCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any): string {
    const currencyValue = this.currencyPipe.transform(value, undefined, "", "1.0-0", "es-CL");
    return "$" + (currencyValue ? currencyValue.trim() : "");
  }
}
