import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({
  name: "clpNumber",
})
export class NumberPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any): string {
    const transformedValue = this.currencyPipe.transform(value, undefined, "", "1.0-0", "es-CL");
    return transformedValue ? transformedValue.trim() : "";
  }
}
