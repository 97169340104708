import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({
  name: "clpCurrencyPesos",
})
export class ChileanCurrencyPipePesos implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any): string {
    const formattedValue = this.currencyPipe.transform(
      Math.round(value / 10) * 10,
      undefined,
      "",
      "1.0-0",
      "es-CL",
    );

    return "$" + formattedValue?.trim();
  }
}
