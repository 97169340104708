import { NgModule } from "@angular/core";
import { CurrencyPipe, registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ngx-currency/src/currency-mask.config";
import { MarkedPipe } from "./marked.pipe";
import { ChileanCurrencyPipePesos } from "./clp-currency-pesos.pipe";
import { ChileanCurrencyPipe } from "./clp-currency.pipe";
import { NumberPipe } from "./number.pipe";
import { RutPipe } from "./rut-pipe.pipe";

registerLocaleData(localeEs);

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "center",
  allowNegative: false,
  allowZero: false,
  precision: 0,
  prefix: "",
  suffix: "",
  thousands: ".",
  decimal: ",",
  nullable: true,
};

@NgModule({
  declarations: [ChileanCurrencyPipe, ChileanCurrencyPipePesos, NumberPipe, MarkedPipe, RutPipe],
  imports: [],
  providers: [
    CurrencyPipe,
    ChileanCurrencyPipe,
    ChileanCurrencyPipePesos,
    NumberPipe,
    MarkedPipe,
    RutPipe,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
  exports: [ChileanCurrencyPipe, ChileanCurrencyPipePesos, NumberPipe, MarkedPipe, RutPipe],
  bootstrap: [],
})
export class PipesModule {}
